import React from "react"
import Layout from "Layouts/default"
import Config from "Data"
import styled from "styled-components"
import { SEO, Link } from "Components"
import { FullWrapper, Gap } from "Styles"
import { theme } from "Theme"

const Content = styled.div`
  width: 100%;
  padding: 1rem 0;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    padding: 0;
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`

const Privacy = () => (
  <Layout locale={"en-us"} region={"us"} sticky={true} transparentHeader={false} transparentFooter={true}>
    <SEO pathname="/privacy/" title={`Privacy Policy | ${Config.brandBusiness} by ${Config.contact.name}`} />
    <Gap />
    <Gap />
    <FullWrapper>
      <Content>
        <h1>Privacy Policy</h1>
        <br />
        <h2>CXOsync Privacy Statement and Consent to Use of Personal Information</h2>
        <p>Effective date: January 1, 2019</p>

        <br />
        <h3>Introduction</h3>
        <br />
        <p>
          {Config.contact.name} LLC (“{Config.contact.name}”) is committed to respecting the personal privacy of
          individuals who use the {Config.contact.name}
          website. This Privacy Statement describes {Config.contact.name}'s current policies and practices with regard
          to personal information collected by {Config.contact.name} through this site. The {Config.contact.name}{" "}
          websites are owned and operated by {Config.contact.name}, a company incorporated in the USA.
        </p>
        <br />
        <h3>Definitions</h3>
        <br />
        <p>
          The terms "you" and "your" refer to you, individually. The term "personal information" means information about
          an identifiable individual, such as, the name, age, e-mail address or mailing address of the individual.
        </p>
        <br />
        <h3>Changes to This Privacy Statement</h3>
        <br />
        <p>
          {Config.contact.name} is continually improving and adding new functionality and features to this site and
          improving and adding to its existing services and programs. Because these ongoing improvements, changes in the
          law and changes in technology may require changes to this Privacy Statement, {Config.contact.name} reserves
          the right to update or modify this Privacy Statement at any time without prior notice. {Config.contact.name}{" "}
          will do so by posting an updated or modified version of this Privacy Statement on this site. Your use of this
          site following any such change constitutes your agreement that personal information collected from or about
          you through this site after the revised Privacy Statement is posted will be subject to the terms of the
          revised Privacy Statement. For this reason, we encourage you to review this Privacy Statement regularly.
        </p>
        <br />
        <h3>Your Consent</h3>
        <br />
        <p>
          This Privacy Statement is included in and part of the engagement with {Config.company}. By agreeing to the
          terms of such engagement (on your own behalf or as an agent for your company), you are consenting to the
          collection, use and disclosure of your personal information as set forth in this Privacy Statement. Your
          continued use of this site or our services constitutes your continuing consent to the terms of this Privacy
          Statement, as from time to time amended. To opt out of post event communications, email us at{" "}
          <Link
            to={`mailto:optout@cxosync.com`}
            style={{
              textDecoration: "underline",
              color: `${theme.colors.primary.base}`,
            }}>
            {"optout@cxosync.com"}
          </Link>
          .
        </p>
        <br />
        <h3>What Personal Information Is Collected?</h3>
        <br />
        <p>
          {Config.contact.name} collects the personal information you supply when you use this site, for example, the
          information you provide when you register to use this site, when you download information, or when you
          personalize this site to meet your specific needs. This information includes, for example, your name, the name
          of your company, your or your company's mailing address, your e-mail address, and information about your
          business interests.
        </p>
        <br />
        <h3>How Does {Config.contact.name} use your Personal Information?</h3>
        <br />
        <p>
          {Config.contact.name} uses the personal information you supply to provide the information, services and
          products you request and to personalize this site to meet your specific needs. We use your e-mail address to
          respond to your comments and questions. We use name, contact, and other information to identify you
          personally.
        </p>
        <p>
          {Config.contact.name} also uses the personal information collected through this site to improve the content
          and functionality of this site, to better understand our customers and markets, to improve our products and
          services and to market our products and services to our customers and potential customers, including those who
          are registered users of this site.
        </p>
        <br />
        <h3>Disclosure of Personal Information</h3>
        <br />
        <p>
          {Config.contact.name} shares personal information collected through this site with its affiliates, who may use
          the information in the same ways as {Config.contact.name} may use the information. If you are an attendee at
          one of {Config.contact.name}'s events, your personal information is also shared with your fellow
          event-attendees.
        </p>
        <p>
          Both {Config.contact.name} and its affiliates may share personal information collected through this site with
          third- party vendors. These companies are not authorized by {Config.contact.name} to use the information we
          share with them for any other purpose.
        </p>
        <p>
          In addition, there may be some other, limited circumstances in which {Config.contact.name} or its affiliates
          may share or transfer the information in their databases to an unrelated third party, for example, to comply
          with a legal requirement, for the administration of justice, to protect your or your company's vital
          interests, or in the event of a corporate sale, merger, reorganization, dissolution or similar event.
        </p>
        <p>
          Where appropriate, before disclosing personal information to a third party, {Config.contact.name} will make
          all reasonable endeavors to protect that data against unauthorized use or disclosure.
        </p>
        <br />
        <h3>Data Integrity and Security</h3>
        <br />
        <p>
          {Config.contact.name} strives to maintain the reliability, accuracy, completeness and currency of personal
          information in our databases and to protect the security of our databases. We keep your personal information
          only for as long as reasonably necessary for the purposes for which it was collected or to comply with any
          applicable legal or ethical reporting or document retention requirements. Our servers and our databases are
          protected by secure technology, such as firewalls and password protection. The employees who have access to
          personal information have been trained in accordance with our security protocols. Although we cannot guarantee
          against any loss, misuse, unauthorized disclosure, alteration or destruction of data, we do try to prevent
          such unfortunate occurrences.
        </p>
        <br />
        <h3>Data Access and Corrections</h3>
        <br />
        <p>
          If you wish to access or correct personal information collected through this site, please contact our
          Governance Risk management & Compliance (GRC) Office at {Config.contact.name}, 1900 E Golf Rd, Schaumburg, IL
          60173 or at info@{Config.contact.name}.com. Please provide enough information to permit us to identify your
          personal information. We will respond to your requests in a prompt and proper manner. If we can accommodate
          your request without incurring significant expense, we will attempt to do so.
        </p>
        <p>
          Requests to correct or delete personal data are subject to any applicable legal and ethical reporting or
          document retention obligations imposed on {Config.contact.name}.
        </p>
        <br />
        <h3>Link to Other sites</h3>
        <br />
        <p>
          This Privacy Statement applies only to {Config.contact.name} websites. {Config.contact.name} and its
          affiliates operate many different types of web sites for different purposes and in different geographical
          regions where different laws may apply. If you visit another {Config.contact.name} web site, please take a
          moment to review the privacy statement posted on that site to learn what personal information may be collected
          through that site and how it is processed.
        </p>
        <p>
          {Config.contact.name} contains hyperlinks to web sites that are not operated by {Config.contact.name} or one
          of its affiliates. These hyperlinks are provided for your reference and convenience only and do not imply any
          endorsement of the activities of these third-party web sites or any association with their operators.{" "}
          {Config.contact.name} does not control these web sites and is not responsible for their data practices.
        </p>
        <br />
        <h3>Transfer of Data Abroad</h3>
        <br />
        <p>
          Of course, the Internet is made up of a large number of international connections. If you are visiting this
          web site from a country other than the country in which our server is located (currently USA), the various
          communications will necessarily result in the transmission of information across international boundaries.
        </p>
        <br />
        <h3>Governing Law</h3>
        <br />
        <p>This privacy policy shall be governed by and construed in accordance with the laws of Illinois, USA.</p>
        <br />
        <h3>Questions About Our Privacy Policy</h3>
        <br />
        <p>
          If you have any questions about this privacy statement or concerns about the way {Config.contact.name}{" "}
          processes your personal information, please write to our Governance Risk management & Compliance (GRC) Office
          at:
          <br />
          {Config.contact.name}, 1900 E Golf Rd, Schaumburg, IL 60173 or at{" "}
          <Link
            to={`mailto:${Config.contact.email}`}
            style={{
              textDecoration: "underline",
              color: `${theme.colors.primary.base}`,
            }}>
            {Config.contact.email}
          </Link>
        </p>
      </Content>
    </FullWrapper>
    <Gap />
  </Layout>
)

export default Privacy
